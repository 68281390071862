import "./NavBar.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsJustify } from "react-icons/bs";
import { useDispatch } from "react-redux";
import axios from "axios";
import { addUser } from "../../rtk/slice/user-slice";
import PrivateRoute from "../PrivateRoute/PrivateRoute";

function NavBar({ OpenSidebar, HideSidebar }) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    try {
      axios.post("/logout").then((res) => {
        if (res.status === 200) {
          dispatch(addUser(null));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [t, i18n] = useTranslation("global");
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg naveee">
        <div className="container-fluid">
          <div className="menu-icon d-none d-lg-block" onClick={OpenSidebar}>
            <BsJustify className="icon-three" />
          </div>

          <div className="menu-icon d-block d-lg-none" onClick={HideSidebar}>
            <BsJustify className="icon-three" />
          </div>

          <div className="tarnslate">
            <select
              className="form-select tarnslate-btn"
              aria-label="Default select example"
              onChange={(e) => handleChangeLanguage(e.target.value)}
            >
              <option value="en" selected>
                {t("tranlate.en")}
              </option>
              <option value="ar">{t("tranlate.ar")}</option>
            </select>
          </div>
          {/* <Link className="navbar-logo kk" to="/">
            <img src={logo} alt="logo" className=" logo-svg" />
          </Link> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse thr"
            id="navbarSupportedContent"
          >
            <ul className="po navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  {t("navBar.home")}
                </Link>
              </li>
              <li className="nav-item">
                {/* <PrivateRoute> */}
                <Link className="nav-link" onClick={handleLogout}>
                  {t("navBar.logout")}
                </Link>
                {/* </PrivateRoute> */}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
