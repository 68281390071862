import "./ResPop.css";
import { useEffect, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/markdown.min.js";
import axios from "axios";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function ResPop(props) {
  const [model, setModel] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [files, setFiles] = useState();
  const [responsed_at, setResponsed_at] = useState();
  const [status_id, setStatus_id] = useState(1);
  const [email_id, setEmail_id] = useState();

  let filesArray = [];

  const handlePostRes = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("responsed_at", responsed_at);
    // formData.append("email_id", props.emailId);

    {
      props.Id && formData.append("email_id", props.Id);
    }

    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
    }
    formData.append("email", email);

    if (props.emailId) {
      try {
        await axios
          .post(`response/update/${props.emailId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {});
      } catch (error) {
        console.log(error);
      } finally {
        window.history.replaceState(null, null, window.location.href);
        window.history.back();
      }
    } else {
      try {
        await axios
          .post("response", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            props.handleEmails();
          });
      } catch (error) {
        console.log(error);
      } finally {
        props.toggleResPop();
      }
    }
  };

  const moveBack = () => {
    window.history.replaceState(null, null, window.location.href);
    window.history.back();
  };

  useEffect(() => {
    setModel(props.email);
  }, [props.email]);

  const handleEditorChange = (content) => {
    setEmail(content);
  };

  return (
    <>
      {/* {model && ( */}
      <>
        <div
          className="pop-blur"
          onClick={props.emailId ? moveBack : props.togglePop}
        ></div>

        <form className="res-pop-form" onSubmit={(e) => handlePostRes(e)}>
          {props.email && (
            <ReactQuill
              className="react-quill mb-5"
              theme="snow"
              // value={email}
              onChange={handleEditorChange}
              // placeholder="Start Writing Your Next Email"
              defaultValue={props.email}
              // value={props.email}
            />
          )}
          <br />

          {props.email ? (
            <></>
          ) : (
            <ReactQuill
              className="react-quill mb-5"
              theme="snow"
              // value={email}
              onChange={handleEditorChange}
              // placeholder="Start Writing Your Next Email"
              defaultValue={props.email}
              // value={props.email}
            />
          )}
          <br />
          {/* <FroalaEditor
              model={model}
              onModelChange={(e) => {
                setModel(e);
                setEmail(e);
              }}
              config={{
                placeholderText: "Start Writing Your Response",
              }}
              tag="textarea"
            />
            <br /> */}

          {/* <input
            className="form-control"
            type="date"
            id="date-input"
            name="date"
            defaultValue={props.received_at}
            onChange={(e) => setResponsed_at(e.target.value)}
          />
          <br /> */}

          {props.received_at && (
            <input
              type="text"
              id="file"
              className="form-control"
              defaultValue={props.files}
            />
          )}
          <input
            className="form-control"
            type="file"
            id="formFileMultiple"
            multiple
            onChange={(e) => {
              setFiles(e.target.files);
              filesArray = [e.target.files];
            }}
          />
          <br />

          <input
            className="form-control"
            type="date"
            id="date-input"
            name="date"
            defaultValue={props.received_at}
            onChange={(e) => setResponsed_at(e.target.value)}
          />

          {props.emailId ? (
            <button type="submit" className="btn btn-primary col-2 mt-5">
              Edit
            </button>
          ) : (
            <button type="submit" className="btn btn-primary col-2 mt-5">
              Add
            </button>
          )}

          {/* <button type="submit" className="btn btn-primary col-2 mt-5">
            Add
          </button> */}
        </form>
      </>
      {/* )} */}
      {/* <div
        className="pop-blur"
        onClick={props.emailId ? moveBack : props.toggleResPop}
      ></div>

      <form className="res-pop-form" onSubmit={(e) => handlePostRes(e)}>
        <FroalaEditor
          model={props.email}
          onModelChange={(e) => {
            setModel(e);
            setEmail(e);
          }}
          config={{
            placeholderText: "Start Writing Your Response",
          }}
          tag="textarea"
        />
        <br />

        <input
          className="form-control"
          type="date"
          id="date-input"
          name="date"
          defaultValue={props.received_at}
          onChange={(e) => setResponsed_at(e.target.value)}
        />
        <br />

        <input
          className="form-control"
          type="file"
          id="formFileMultiple"
          multiple
          onChange={(e) => {
            setFiles(e.target.files);
            filesArray = [e.target.files];
          }}
        />

        <button type="submit" className="btn btn-primary col-2 mt-5">
          Add
        </button>
      </form> */}
    </>
  );
}

export default ResPop;
