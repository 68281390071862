import "./EmailsPop.css";
import { useEffect, useState } from "react";
import axios from "axios";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/markdown.min.js";
import "froala-editor/js/plugins/save.min.js";

import { useTranslation } from "react-i18next";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EmailsPop(props) {
  // const defaultValue = "This is the default value";

  const [email, setEmail] = useState();

  const [received_at, setReceived_at] = useState();
  const [receivedStatuses, setReceivedStatuses] = useState([]);
  const [statusId, setStatusId] = useState();
  const [file, setFile] = useState();
  const [files, setFiles] = useState([]);

  const [ip, setIp] = useState();
  const [number, setnNumber] = useState();
  const [subject, setSubject] = useState();
  const [model, setModel] = useState(props.email);

  const [t, i18n] = useTranslation("global");

  let filesArray = [];

  const handlePostEmail = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("received_at", received_at);
    // formData.append("status_id", statusId);
    formData.append("subject", subject);
    formData.append("ip", ip);
    // formData.append("files", fff);

    // filesArray.forEach((file, index) => {
    //   formData.append(`files[${index}]`, file);
    // });

    for (let i = 0; i < files.length; i++) {
      formData.append(`files[${i}]`, files[i]);
    }

    formData.append("email", email);
    if (props.emailId) {
      try {
        await axios
          .post(`email/update/${props.emailId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            // console.log(formData.append("files", files));
            // props.handleEmails();
            // props.toggleModal();
          });
      } catch (error) {
        console.log(error);
      } finally {
        window.history.replaceState(null, null, window.location.href);
        window.history.back();
      }
    } else {
      try {
        await axios
          .post("email", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            props.handleEmails();
          });
      } catch (error) {
        console.log(error);
      } finally {
        props.toggleModal();
      }
    }
  };

  const moveBack = () => {
    window.history.replaceState(null, null, window.location.href);
    window.history.back();
  };

  useEffect(() => {
    setModel(props.email);
  }, [props.email]);

  useEffect(() => {
    // handleStatuses();
  }, []);

  // const handleStatuses = async () => {
  //   await axios.get("statuses").then((res) => {
  //     setReceivedStatuses(res.data.data);
  //   });
  // };

  const handleEditorChange = (content) => {
    setEmail(content);
  };

  return (
    <>
      {/* {model && ( */}
      <div className="h-100">
        <div
          className="pop-blur"
          onClick={props.emailId ? moveBack : props.toggleModal}
        ></div>

        <form className="emails-pop-form" onSubmit={(e) => handlePostEmail(e)}>
          {props.emailId ? (
            <p className="p-pop">{t("emails.editEmail")}</p>
          ) : (
            <p className="p-pop">{t("emails.addEmail")}</p>
          )}

          {/* <p>{t("emails.addEmail")}</p> */}

          <label className="users-pop-form-label">Ip :</label>
          <input
            type="text"
            id="subject"
            className="form-control"
            defaultValue={props.ip}
            onChange={(e) => setIp(e.target.value)}
          />
          <br />

          <label className="users-pop-form-label">{t("emails.title")} :</label>
          <input
            type="text"
            id="ip"
            className="form-control"
            defaultValue={props.subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <br />

          {props.email && (
            <ReactQuill
              className="react-quill"
              theme="snow"
              // value={email}
              onChange={handleEditorChange}
              // placeholder="Start Writing Your Next Email"
              defaultValue={props.email}
              // value={props.email}
            />
          )}
          <br />

          {props.email ? (
            <></>
          ) : (
            <ReactQuill
              className="react-quill"
              theme="snow"
              // value={email}
              onChange={handleEditorChange}
              // placeholder="Start Writing Your Next Email"
              defaultValue={props.email}
              // value={props.email}
            />
          )}
          <br />

          {/* <FroalaEditor
            model={model}
            // model={props.email}
            onModelChange={(e) => {
              setModel(e);
              setEmail(e);
            }}
            config={{
              placeholderText: "start writing your next email",
              saveInterval: 2000,
              events: {
                "save.before": function (html) {
                  setModel(html);
                },
              },
            }}
            tag="textarea"
          /> */}
          <br />

          <div className="mb-3">
            <label className="form-label">Choose Files</label>

            {props.received_at && (
              <input
                type="text"
                id="file"
                className="form-control"
                defaultValue={props.files}
              />
            )}

            <input
              className="form-control"
              type="file"
              id="formFileMultiple"
              // defaultValue={props.files}
              multiple
              onChange={(e) => {
                // console.log(e.target.files);
                setFiles(e.target.files);
                filesArray = [e.target.files];
                // handleTeeeeeeest(e);
              }}
            />
          </div>

          <input
            className="form-control"
            type="date"
            id="date-input"
            name="date"
            defaultValue={props.received_at}
            onChange={(e) => setReceived_at(e.target.value)}
          />
          <br />

          {/* <label className="form-label">Choose status</label> */}

          {/* <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e) => setStatusId(e.target.value)}
          >
            {receivedStatuses?.map((stat) => (
              <>
                <option hidden></option>
                <option value={stat.id} selected={props.status == stat.id}>
                  {stat.status_name}
                </option>
              </>
            ))}
          </select> */}

          {props.emailId ? (
            <button type="submit" className="btn btn-primary col-2 mt-5">
              {t("control.edit")}
            </button>
          ) : (
            <button type="submit" className="btn btn-primary col-2 mt-5">
              {t("control.add")}
            </button>
          )}

          {/* <button type="submit" className="btn btn-primary col-2 mt-5">
            Add
          </button> */}
        </form>
      </div>
      {/* )} */}
      {/* <div
        className="pop-blur"
        onClick={props.emailId ? moveBack : props.toggleModal}
      ></div>

      <form className="emails-pop-form" onSubmit={(e) => handlePostEmail(e)}>
        <p>Add Email</p>

        <FroalaEditor
          model={model}
          // model={props.email}
          onModelChange={(e) => {
            setModel(e);
            setEmail(e);
          }}
          config={{
            placeholderText: "start writing your next email",
            saveInterval: 2000,
            events: {
              "save.before": function (html) {
                setModel(html);
              },
            },
          }}
          tag="textarea"
        />
        <br />

        <div className="mb-3">
          <label className="form-label">Choose Files</label>

          {props.received_at && (
            <input
              type="text"
              id="file"
              className="form-control"
              defaultValue={props.files}
            />
          )}

          <input
            className="form-control"
            type="file"
            id="formFileMultiple"
            // defaultValue={props.files}
            multiple
            onChange={(e) => {
              // console.log(e.target.files);
              setFiles(e.target.files);
              filesArray = [e.target.files];
              // handleTeeeeeeest(e);
            }}
          />
        </div>

        <input
          className="form-control"
          type="date"
          id="date-input"
          name="date"
          defaultValue={props.received_at}
          onChange={(e) => setReceived_at(e.target.value)}
        />

        <button type="submit" className="btn btn-primary col-2 mt-5">
          Add
        </button>
      </form> */}
    </>
  );
}

export default EmailsPop;
