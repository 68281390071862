import { useState } from "react";
import "./StatusesPop.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

function StatusesPop(props) {
  const [name, setName] = useState([]);
  const [color, setColor] = useState([]);

  const [t, i18n] = useTranslation("global");

  const handlePostColor = async (e) => {
    e.preventDefault();
    if (props.statusId) {
      try {
        await axios
          .post(`statuses/update/${props.statusId}`, {
            status_name: name,
            status_color: color,
          })
          .then(() => {});
      } catch (error) {
        console.log(error);
      } finally {
        window.history.replaceState(null, null, window.location.href);
        window.history.back();
      }
    } else {
      try {
        await axios
          .post("statuses", {
            status_name: name,
            status_color: color,
          })
          .then(() => {
            props.handleStatuses();
          });
      } catch (error) {
        console.log(error);
      } finally {
        props.toggleModal();
      }
    }
  };

  const moveBack = () => {
    window.history.replaceState(null, null, window.location.href);
    window.history.back();
  };

  return (
    <>
      <div
        className="pop-blur"
        onClick={props.statusId ? moveBack : props.toggleModal}
        // onClick={props.toggleModal}
      ></div>
      <form className="statuses-pop-form" onSubmit={(e) => handlePostColor(e)}>
        <label className="users-pop-form-label">Statues :</label>

        <input
          className="form-control"
          type="text"
          aria-label="default input example"
          defaultValue={props.name}
          onChange={(e) => setName(e.target.value)}
        />
        <br />

        <label className="users-pop-form-label color-label">
          Select Color :
        </label>
        {props.color && (
          <input
            type="color"
            id="favcolor"
            name="favcolor"
            defaultValue={props.color}
            onChange={(e) => setColor(e.target.value)}
          />
        )}

        <br />

        {props.statusId ? (
          <button type="submit" className="btn btn-primary col-2 mt-5">
            Edit
          </button>
        ) : (
          <button type="submit" className="btn btn-primary col-2 mt-5">
            Add
          </button>
        )}

        {/* <button type="submit" className="btn btn-primary col-2 mt-5">
          Add
        </button> */}
      </form>
    </>
  );
}

export default StatusesPop;
