import { useLocation, useParams } from "react-router-dom";
import "./EmailPage.css";
import EmailsPop from "../../Components/EmailsPop/EmailsPop";
import axios from "axios";
import { useEffect, useState } from "react";

function EmailPage() {
  const [email, setEmail] = useState();
  const [received_at, setReceived_at] = useState();
  const [files, setFiles] = useState();
  const [received_email, setReceived_email] = useState();
  const [status, setStatus] = useState();
  const [ip, setIp] = useState();
  const [subject, setSubject] = useState();

  let { emailId } = useParams();

  const location = useLocation();
  const perPage = location.state?.perPage;
  const currentPage = location.state?.currentPage;

  const emailbyId = async () => {
    await axios.get(`/email/${emailId}`).then((res) => {
      const firstItem = res.data.data[0];
      console.log(res.data.data);
      setReceived_email(firstItem.received_email);
      setEmail(firstItem.email);
      setReceived_at(firstItem.received_at);
      setFiles(firstItem.files.map((file) => file.file_name));
      setStatus(firstItem.statuses[0].id);
      setIp(firstItem.ip);
      setSubject(firstItem.subject);
      // console.log(firstItem.email);
    });
  };

  useEffect(() => {
    emailbyId();
  }, []);

  return (
    <>
      <EmailsPop
        emailId={emailId}
        perPage={perPage}
        currentPage={currentPage}
        subject={subject}
        ip={ip}
        email={email}
        received_email={received_email}
        received_at={received_at}
        status={status}
        files={files}
      />
    </>
  );
}

export default EmailPage;
