import { useEffect, useState } from "react";
import "./ChangeStatusPage.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

function ChangeStatusPage(props) {
  let { emailId } = useParams();

  const [receivedStatuses, setReceivedStatuses] = useState([]);
  const [statusId, setStatusId] = useState();
  const [emailStatuses, setEmailStatuses] = useState([]);
  const [notes, setNotes] = useState([]);

  const [t, i18n] = useTranslation("global");

  const location = useLocation();
  const last = location.state?.last;
  const note = location.state?.note;

  const handleStatuses = async () => {
    await axios.get("statuses").then((res) => {
      setReceivedStatuses(res.data.data);
    });
  };

  const handleEmailStatuses = async () => {
    await axios.get(`email/indexNoticeStatuses/${emailId}`).then((res) => {
      setEmailStatuses(res.data.data);
      let firstName = res.data.data;
      // console.log(firstName[0]);
    });
  };

  useEffect(() => {
    handleStatuses();
    handleEmailStatuses();
  }, []);

  const handlePostEmailStatus = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post("noticestatuses", {
          notice_id: emailId,
          status_id: statusId,
          notes,
        })
        .then((res) => {
          props.handleEmails();
        });
    } catch (error) {
      console.log(error);
    } finally {
      window.history.replaceState(null, null, window.location.href);
      window.history.back();
    }
  };

  const moveBack = () => {
    window.history.replaceState(null, null, window.location.href);
    window.history.back();
  };

  return (
    <>
      <div
        className="pop-blur"
        // onClick={props.emailId ? moveBack : props.togglePop}
        onClick={moveBack}
      ></div>
      <form
        className="changestatus-pop-form"
        onSubmit={(e) => handlePostEmailStatus(e)}
      >
        <label className="users-pop-form-label">Select Status :</label>
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={(e) => setStatusId(e.target.value)}
        >
          {receivedStatuses?.map((stat) => (
            <>
              <option hidden></option>
              <option value={stat.id} selected={last == stat.status_name}>
                {stat.status_name}
              </option>
            </>
          ))}
        </select>

        <label className="users-pop-form-label">Write Your Note :</label>
        <input
          type="text"
          id="username"
          className="form-control"
          defaultValue={note}
          onChange={(e) => setNotes(e.target.value)}
        />

        <table className="tab-style table table-striped table-hover mt-5">
          <thead>
            <tr>
              <td>status</td>
              <td>notes</td>
            </tr>
          </thead>
          <tbody>
            {emailStatuses.map((es) =>
              es.map((se) => (
                <tr key={se.id}>
                  <td>{se.status_name}</td>
                  <td>{se.notes}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <button type="submit" className="btn btn-primary col-2 mt-5">
          {t("control.add")}
        </button>
      </form>
    </>
  );
}

export default ChangeStatusPage;
