import "./App.css";
import "./axios";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import NavBar from "./Components/NavBar/NavBar";
import SideBar from "./Components/SideBar/SideBar";
import Emails from "./Pages/Emails/Emails";
import Users from "./Pages/Users/Users";
import Statuses from "./Pages/Statuses/Statuses";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import { addUser } from "./rtk/slice/user-slice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ClockLoader from "react-spinners/ClockLoader";
import UserPage from "./Pages/UserPage/UserPage";
import StatusPage from "./Pages/StatusPage/StatusPage";
import EmailPage from "./Pages/EmailPage/EmailPage";
import ResponsePage from "./Pages/ResponsePage/ResponsePage";
import ChangeStatusPage from "./Components/ChangeStatusPage/ChangeStatusPage";
import EmpSideBar from "./Components/EmpSideBar/EmpSideBar";
// import { CheckPrivilege } from "./Components/CheckPrivilege/CheckPrivilege";

function App() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [hideSidebarToggle, sethideSidebarToggle] = useState(false);
  const [loading, setLoading] = useState(true);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
    sethideSidebarToggle(false);
  };

  const HideSidebar = () => {
    sethideSidebarToggle(!hideSidebarToggle);
    setOpenSidebarToggle(true);
  };

  const dispatch = useDispatch();

  const store = useSelector((store) => store.user?.position);

  useEffect(() => {
    const checkUser = async () => {
      try {
        await axios.get("/user").then((res) => {
          if (res.status === 200) {
            console.log(res.data.data);
            dispatch(addUser(res.data.data));
            // navigate("/");
            // setLoading(true);
          } else {
            console.log("error");
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    checkUser();
  }, []);

  const result =
    store === "admin" ? (
      <div className="App">
        {
          loading ? (
            <div className="loading">
              <ClockLoader
                className="loading"
                // color={"#36d7b7"}
                color={"#4fe000"}
                loading={loading}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div className="container-fluid height-co vh-100">
              <Routes>
                <Route path="/login" element={<Login />} />
              </Routes>

              <PrivateRoute>
                <div className="row h-100 overflow-hidden">
                  <div
                    className={hideSidebarToggle ? "pop-blur" : ""}
                    onClick={HideSidebar}
                  ></div>

                  <div
                    id="sidediv"
                    className={
                      (openSidebarToggle ? "col-2 joao visible" : "col hidden",
                      hideSidebarToggle
                        ? "col visible  mobile-sidediv d-inline z-in position-absolute"
                        : "joao hidden")
                    }
                  >
                    <SideBar
                      OpenSidebar={OpenSidebar}
                      openSidebarToggle={openSidebarToggle}
                    />
                  </div>

                  <div
                    id="pep"
                    className={
                      (openSidebarToggle ? "col-11" : "col-10",
                      hideSidebarToggle ? "col-12" : "col joao1")
                    }
                  >
                    <div>
                      <NavBar
                        OpenSidebar={OpenSidebar}
                        HideSidebar={HideSidebar}
                      />
                    </div>

                    <div id="abde">
                      <Routes>
                        <Route path="/emails" element={<Emails />} />

                        <Route
                          path="/emails/:emailId"
                          element={<EmailPage />}
                        />
                        <Route
                          path="/emailsResponse/:emailId"
                          element={<ResponsePage />}
                        />
                        <Route
                          path="/emailsStatus/:emailId"
                          element={<ChangeStatusPage />}
                        />
                        <Route path="/users" element={<Users />} />
                        <Route path="/users/:userId" element={<UserPage />} />
                        <Route path="/statuses" element={<Statuses />} />
                        <Route
                          path="/statuses/:statusId"
                          element={<StatusPage />}
                        />
                      </Routes>
                    </div>
                  </div>
                </div>
              </PrivateRoute>
            </div>
          )

          // <div className="container-fluid height-co vh-100">
          //   <Routes>
          //     <Route path="/login" element={<Login />} />
          //   </Routes>

          //   <PrivateRoute>
          //     <div className="row h-100 overflow-hidden">
          //       <div
          //         className={hideSidebarToggle ? "pop-blur" : ""}
          //         onClick={HideSidebar}
          //       ></div>

          //       <div
          //         id="sidediv"
          //         className={
          //           (openSidebarToggle ? "col-2 joao visible" : "col hidden",
          //           hideSidebarToggle
          //             ? "col visible  mobile-sidediv d-inline z-in position-absolute"
          //             : "joao hidden")
          //         }
          //       >
          //         <SideBar
          //           OpenSidebar={OpenSidebar}
          //           openSidebarToggle={openSidebarToggle}
          //         />
          //       </div>

          //       <div
          //         id="pep"
          //         className={
          //           (openSidebarToggle ? "col-11" : "col-10",
          //           hideSidebarToggle ? "col-12" : "col joao1")
          //         }
          //       >
          //         <div>
          //           <NavBar OpenSidebar={OpenSidebar} HideSidebar={HideSidebar} />
          //         </div>

          //         <div id="abde">
          //           <Routes>
          //             <Route path="/emails" element={<Emails />} />

          //             <Route path="/emails/:emailId" element={<EmailPage />} />
          //             <Route
          //               path="/emailsResponse/:emailId"
          //               element={<ResponsePage />}
          //             />
          //             <Route
          //               path="/emailsStatus/:emailId"
          //               element={<ChangeStatusPage />}
          //             />
          //             <Route path="/users" element={<Users />} />
          //             <Route path="/users/:userId" element={<UserPage />} />
          //             <Route path="/statuses" element={<Statuses />} />
          //             <Route
          //               path="/statuses/:statusId"
          //               element={<StatusPage />}
          //             />
          //           </Routes>
          //         </div>
          //       </div>
          //     </div>
          //   </PrivateRoute>
          // </div>
        }
      </div>
    ) : (
      <div className="App">
        {loading ? (
          <div className="loading">
            <ClockLoader
              className="loading"
              // color={"#36d7b7"}
              color={"#4fe000"}
              loading={loading}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <div className="container-fluid height-co vh-100">
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>

            <PrivateRoute>
              <div className="row h-100 overflow-hidden">
                <div
                  className={hideSidebarToggle ? "pop-blur" : ""}
                  onClick={HideSidebar}
                ></div>

                <div
                  id="sidediv"
                  className={
                    (openSidebarToggle ? "col-2 joao visible" : "col hidden",
                    hideSidebarToggle
                      ? "col visible  mobile-sidediv d-inline z-in position-absolute"
                      : "joao hidden")
                  }
                >
                  {/* <SideBar
                      OpenSidebar={OpenSidebar}
                      openSidebarToggle={openSidebarToggle}
                    /> */}
                  <EmpSideBar
                    OpenSidebar={OpenSidebar}
                    openSidebarToggle={openSidebarToggle}
                  />
                </div>

                <div
                  id="pep"
                  className={
                    (openSidebarToggle ? "col-11" : "col-10",
                    hideSidebarToggle ? "col-12" : "col joao1")
                  }
                >
                  <div>
                    <NavBar
                      OpenSidebar={OpenSidebar}
                      HideSidebar={HideSidebar}
                    />
                  </div>

                  <div id="abde">
                    <Routes>
                      <Route path="/emails" element={<Emails />} />

                      <Route path="/emails/:emailId" element={<EmailPage />} />
                      <Route
                        path="/emailsResponse/:emailId"
                        element={<ResponsePage />}
                      />
                      <Route
                        path="/emailsStatus/:emailId"
                        element={<ChangeStatusPage />}
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </PrivateRoute>
          </div>
        )}
      </div>
    );

  return (
    // <div className="App">
    // {
    //   loading ? (
    //     <div className="loading">
    //       <ClockLoader
    //         className="loading"
    //         // color={"#36d7b7"}
    //         color={"#4fe000"}
    //         loading={loading}
    //         size={150}
    //         aria-label="Loading Spinner"
    //         data-testid="loader"
    //       />
    //     </div>
    //   ) : (
    //     <div className="container-fluid height-co vh-100">
    //       <Routes>
    //         <Route path="/login" element={<Login />} />
    //       </Routes>

    //       <PrivateRoute>
    //         <div className="row h-100 overflow-hidden">
    //           <div
    //             className={hideSidebarToggle ? "pop-blur" : ""}
    //             onClick={HideSidebar}
    //           ></div>

    //           <div
    //             id="sidediv"
    //             className={
    //               (openSidebarToggle ? "col-2 joao visible" : "col hidden",
    //               hideSidebarToggle
    //                 ? "col visible  mobile-sidediv d-inline z-in position-absolute"
    //                 : "joao hidden")
    //             }
    //           >
    //             <SideBar
    //               OpenSidebar={OpenSidebar}
    //               openSidebarToggle={openSidebarToggle}
    //             />
    //           </div>

    //           <div
    //             id="pep"
    //             className={
    //               (openSidebarToggle ? "col-11" : "col-10",
    //               hideSidebarToggle ? "col-12" : "col joao1")
    //             }
    //           >
    //             <div>
    //               <NavBar
    //                 OpenSidebar={OpenSidebar}
    //                 HideSidebar={HideSidebar}
    //               />
    //             </div>

    //             <div id="abde">
    //               <Routes>
    //                 <Route path="/emails" element={<Emails />} />

    //                 <Route path="/emails/:emailId" element={<EmailPage />} />
    //                 <Route
    //                   path="/emailsResponse/:emailId"
    //                   element={<ResponsePage />}
    //                 />
    //                 <Route
    //                   path="/emailsStatus/:emailId"
    //                   element={<ChangeStatusPage />}
    //                 />
    //                 <Route path="/users" element={<Users />} />
    //                 <Route path="/users/:userId" element={<UserPage />} />
    //                 <Route path="/statuses" element={<Statuses />} />
    //                 <Route
    //                   path="/statuses/:statusId"
    //                   element={<StatusPage />}
    //                 />
    //               </Routes>
    //             </div>
    //           </div>
    //         </div>
    //       </PrivateRoute>
    //     </div>
    //   )

    //   // <div className="container-fluid height-co vh-100">
    //   //   <Routes>
    //   //     <Route path="/login" element={<Login />} />
    //   //   </Routes>

    //   //   <PrivateRoute>
    //   //     <div className="row h-100 overflow-hidden">
    //   //       <div
    //   //         className={hideSidebarToggle ? "pop-blur" : ""}
    //   //         onClick={HideSidebar}
    //   //       ></div>

    //   //       <div
    //   //         id="sidediv"
    //   //         className={
    //   //           (openSidebarToggle ? "col-2 joao visible" : "col hidden",
    //   //           hideSidebarToggle
    //   //             ? "col visible  mobile-sidediv d-inline z-in position-absolute"
    //   //             : "joao hidden")
    //   //         }
    //   //       >
    //   //         <SideBar
    //   //           OpenSidebar={OpenSidebar}
    //   //           openSidebarToggle={openSidebarToggle}
    //   //         />
    //   //       </div>

    //   //       <div
    //   //         id="pep"
    //   //         className={
    //   //           (openSidebarToggle ? "col-11" : "col-10",
    //   //           hideSidebarToggle ? "col-12" : "col joao1")
    //   //         }
    //   //       >
    //   //         <div>
    //   //           <NavBar OpenSidebar={OpenSidebar} HideSidebar={HideSidebar} />
    //   //         </div>

    //   //         <div id="abde">
    //   //           <Routes>
    //   //             <Route path="/emails" element={<Emails />} />

    //   //             <Route path="/emails/:emailId" element={<EmailPage />} />
    //   //             <Route
    //   //               path="/emailsResponse/:emailId"
    //   //               element={<ResponsePage />}
    //   //             />
    //   //             <Route
    //   //               path="/emailsStatus/:emailId"
    //   //               element={<ChangeStatusPage />}
    //   //             />
    //   //             <Route path="/users" element={<Users />} />
    //   //             <Route path="/users/:userId" element={<UserPage />} />
    //   //             <Route path="/statuses" element={<Statuses />} />
    //   //             <Route
    //   //               path="/statuses/:statusId"
    //   //               element={<StatusPage />}
    //   //             />
    //   //           </Routes>
    //   //         </div>
    //   //       </div>
    //   //     </div>
    //   //   </PrivateRoute>
    //   // </div>
    // }
    // </div>
    <div className="App">{result}</div>
  );
}

export default App;
