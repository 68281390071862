import axios from "axios";
import "./UsersPop.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function UsersPop(props) {
  const [t, i18n] = useTranslation("global");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [position, setPosition] = useState();

  const handlePostUsers = async (e) => {
    e.preventDefault();
    if (props.userId) {
      try {
        axios
          .post(`users/update/${props.userId}`, {
            name,
            email,
            password,
            position,
          })
          .then(() => {});
      } catch (error) {
        console.log(error);
      } finally {
        console.log(name, email, password, position);
        window.history.replaceState(null, null, window.location.href);
        window.history.back();
      }
    } else {
      try {
        await axios
          .post("users", {
            name,
            email,
            password,
            position,
          })
          .then(() => {
            console.log(name, email, password, position);
            props.handleUsers();
          });
      } catch (error) {
        console.log(error);
      } finally {
        props.toggleModal();
      }
    }
  };

  const moveBack = () => {
    window.history.replaceState(null, null, window.location.href);
    window.history.back();
  };

  return (
    <>
      <div
        className="pop-blur"
        onClick={props.userId ? moveBack : props.toggleModal}
        // onClick={props.toggleModal}
      ></div>
      <form className="users-pop-form" onSubmit={(e) => handlePostUsers(e)}>
        <label className="users-pop-form-label">{t("users.userName")} :</label>
        <input
          type="text"
          id="username"
          className="form-control"
          defaultValue={props.name}
          onChange={(e) => setName(e.target.value)}
        />
        <br />

        <label className="users-pop-form-label">{t("users.email")} :</label>
        <input
          type="text"
          id="email"
          className="form-control"
          defaultValue={props.email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />

        <label className="users-pop-form-label">{t("users.password")} :</label>
        <input
          type="password"
          id="password"
          className="form-control"
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />

        <label className="users-pop-form-label">{t("users.position")} :</label>
        <select
          className="form-select status-select"
          aria-label="Default select example"
          onChange={(e) => {
            setPosition(e.target.value);
            console.log(e.target.value);
          }}
        >
          <option hidden></option>
          <option value="admin" selected={props.position === "admin"}>
            admin
          </option>
          <option value="emp" selected={props.position === "emp"}>
            emp
          </option>
        </select>
        <br />

        {props.userId ? (
          <button type="submit" className="btn btn-primary col-2 mt-5">
            Edit
          </button>
        ) : (
          <button type="submit" className="btn btn-primary col-2 mt-5">
            Add
          </button>
        )}
        {/* <button type="submit" className="btn btn-primary col-2 mt-5">
          Add
        </button> */}
      </form>
    </>
  );
}

export default UsersPop;
