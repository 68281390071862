import "./EmpSideBar.css";
import { Link, NavLink } from "react-router-dom";
import { GrStatusGood } from "react-icons/gr";
import { TfiEmail } from "react-icons/tfi";
import { FaUser } from "react-icons/fa";
import logo from "../../images/logo.svg";
import { useTranslation } from "react-i18next";

function EmpSideBar({ openSidebarToggle, OpenSidebar }) {
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <aside id="sidebar">
        {/* <div className="sidebar-title">
          <span className="icon close_icon" onClick={OpenSidebar}>
            X
          </span>
        </div> */}
        <Link className={openSidebarToggle ? "side-logo" : "felex"} to="/">
          <img
            src={logo}
            alt="logo"
            className={openSidebarToggle ? "side-logo" : "felex"}
          />
        </Link>

        <ul className="sidebar-list">
          <NavLink to="/emails" className="q">
            <li className="sidebar-list-item">
              <TfiEmail className="icon" />
              <label className={openSidebarToggle ? "ssss" : "felex"}>
                {t("sideBar.emails")}
              </label>
            </li>
          </NavLink>
        </ul>
      </aside>
    </>
  );
}

export default EmpSideBar;
