import "./Login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addUser } from "../../rtk/slice/user-slice";
import logo from "../../images/logo-1.svg";

function Login() {
  const API_URL = "highspeed.com";

  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const disptach = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    var csrf_url = "http://localhost:8000/sanctum/csrf-cookie";
    if (window.location.hostname == "btk.highspeed.com.tr") {
      csrf_url = "https://btk.highspeed.com.tr/server/sanctum/csrf-cookie";
    }
    const csrf = axios.get(csrf_url).then((response) => {
      axios
        .post("login", {
          email,
          password,
        })
        .then((res) => {
          if (res.status == 200) {
            disptach(addUser(res.data.user));
            // console.log(res.data.user);
            navigate("/");
          } else if (
            res.status == 240 &&
            res.data.message == "invalid_credintials"
          ) {
            setErrorMessage("البيانات خطأ, حاول مجدداً");
          } else if (
            res.status == 240 &&
            res.data.message == "already_logged_in"
          ) {
            setErrorMessage("المستخدم مسجل مسبقا");
          }
        });
    });
  };

  return (
    <>
      <div className="container login">
        <div className="row ">
          <div className="login-page d-flex justify-content-center">
            <div className="col-12 col-md-6 col-lg-4 login-card ">
              <div className="login-logo">
                <img src={logo} />
                <p className="high-speed">High Speed Archive </p>
              </div>

              {/* <div className="container">
                <div className="row d-flex justify-content-around">
                  <div className="google col-10 col-lg-5">
                    <FontAwesomeIcon icon={faGoogle} />
                    <button type="text" className="third-party-btn">
                      sign in with google
                    </button>
                  </div>

                  <div className="facebook col-10 col-lg-5">
                    <FontAwesomeIcon icon={faFacebook} />
                    <button type="text" className="third-party-btn">
                      sign in with fB
                    </button>
                  </div>
                </div>
              </div> */}

              <div className="login-with-email">
                {/* <p className="or-sign">or sign in with</p> */}

                <form onSubmit={handleLogin} className="login-form">
                  <label className="user">user name</label>
                  <input
                    type="text"
                    id="username"
                    className="info"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="pass">password</label>
                  <input
                    type="password"
                    id="password"
                    className="info"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="login-options">
                    <div className="login-options-remember">
                      <input type="checkbox" className="remember-me" />
                      <label className="remember">remember me</label>
                    </div>
                    <p className="forget">forget password?</p>
                  </div>
                  <button type="submit" className="sign-in-btn">
                    sign in
                  </button>
                  {/* {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                  )} */}
                </form>
                {/* <div className="create-account">create an account</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
