import { useEffect, useState } from "react";
import "./ResponsePage.css";
import ResPop from "../../Components/ResPop/ResPop";
import { useParams } from "react-router-dom";
import axios from "axios";

function ResponsePage(props) {
  let { emailId } = useParams();
  const [model, setModel] = useState();
  const [subject, setSubject] = useState();
  const [files, setFiles] = useState();
  const [received_at, setReceived_at] = useState();
  const [status_id, setStatus_id] = useState(1);
  const [email_id, setEmail_id] = useState();
  const [email, setEmail] = useState();

  const resbyId = async () => {
    await axios.get(`response/${emailId}`).then((res) => {
      console.log(res.data.data);
      const firstItem = res.data.data[0];
      setEmail(firstItem.email);
      setSubject(firstItem.id);
      setReceived_at(firstItem.responsed_at);
      setFiles(firstItem.files.map((file) => file.file_name));
      //   console.log(firstItem.email);
    });
  };

  useEffect(() => {
    resbyId();
  }, []);

  return (
    <>
      <ResPop
        emailId={emailId}
        email={email}
        received_at={received_at}
        files={files}
      />
    </>
  );
}

export default ResponsePage;
