import { useEffect, useState } from "react";
import StatusesPop from "../../Components/StatusesPop/StatusesPop";
import "./StatusPage.css";
import { useParams } from "react-router-dom";
import axios from "axios";

function StatusPage() {
  let { statusId } = useParams();
  const [name, setName] = useState();
  const [color, setColor] = useState();

  const statusbyId = async () => {
    await axios.get(`/statuses/${statusId}`).then((res) => {
      setName(res.data.data.status_name);
      setColor(res.data.data.status_color);
      console.log(res.data.data.status_color);
    });
  };

  useEffect(() => {
    statusbyId();
  }, []);

  return (
    <>
      <StatusesPop statusId={statusId} name={name} color={color} />
    </>
  );
}

export default StatusPage;
