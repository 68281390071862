import "./Statuses.css";
import { useEffect, useState } from "react";
import StatusesPop from "../../Components/StatusesPop/StatusesPop";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";

function Statuses() {
  const [model, setModel] = useState();
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);

  const [t, i18n] = useTranslation("global");

  const toggleModal = () => {
    setModel(!model);
  };

  const handleStatuses = () => {
    axios.get("statuses").then((res) => {
      setStatuses(res.data.data);
      setLoading(false);
    });
  };

  const handleDelete = async (status) => {
    try {
      setStatuses(statuses.filter((p) => p.id !== status.id));
      await axios.delete(`statuses/delete/${status.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleStatuses();
  }, []);

  return (
    <>
      <button className="btn btn-primary mt-5 col-2" onClick={toggleModal}>
        {t("control.add")}
      </button>

      {model && (
        <StatusesPop
          toggleModal={toggleModal}
          handleStatuses={handleStatuses}
        />
      )}

      <div className="overflow-x-auto mb-4">
        <table className="tab-style table table-striped table-hover mt-5">
          <thead>
            <tr>
              <td>Id</td>
              <td>{t("statuses.status")}</td>
              <td>{t("statuses.color")}</td>
              <td>{t("statuses.created at")}</td>
              <td>{t("statuses.Updated at")}</td>
              <td>{t("control.actions")}</td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <>
                <div className="pop-blur"></div>
                <tr>
                  <td colSpan="15">
                    <div className="din">
                      <ClockLoader
                        color={"#4fe000"}
                        loading={loading}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  </td>
                </tr>
              </>
            ) : (
              statuses?.map((status) => (
                <tr key={status.id}>
                  <td>{status.id}</td>
                  <td>{status.status_name}</td>
                  {/* <td>{status.status_color}</td> */}
                  <td>
                    <input
                      type="color"
                      id="favcolor"
                      name="favcolor"
                      value={status.status_color}
                      disabled
                      // onChange={(e) => setColor(e.target.value)}
                    />
                  </td>
                  <td>{status.created_at}</td>
                  <td>{status.updated_at}</td>
                  <td>
                    <div className="events">
                      <Link
                        to={`/statuses/${status.id}`}
                        state={
                          {
                            // perPage,
                            // currentPage,
                            // handleUsers: handleUsers,
                          }
                        }
                        className="btn btn-success centers-action-btn"
                      >
                        {t("control.edit")}
                      </Link>
                    </div>
                    <div className="events">
                      <button
                        type="button"
                        className="btn btn-danger centers-action-btn"
                        onClick={() => handleDelete(status)}
                      >
                        {t("control.delete")}
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Statuses;
