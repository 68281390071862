import axios from "axios";
import UsersPop from "../../Components/UsersPop/UsersPop";
import "./UserPage.css";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

function UserPage() {
  const [data, setData] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [position, setPosition] = useState();

  const location = useLocation();
  const perPage = location.state?.perPage;
  const currentPage = location.state?.currentPage;
  let { userId } = useParams();

  const userbyId = async () => {
    await axios.get(`/users/${userId}`).then((res) => {
      setData(res.data.data);
      //   console.log(res.data.data);
      const firstItem = res.data.data[0];
      setName(firstItem.name);
      setEmail(firstItem.email);
      setPosition(firstItem.position);
    });
  };

  useEffect(() => {
    userbyId();
  }, []);

  return (
    <>
      <UsersPop
        perPage={perPage}
        currentPage={currentPage}
        userId={userId}
        name={name}
        email={email}
        position={position}
      />
    </>
  );
}

export default UserPage;
