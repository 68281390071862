import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const store = useSelector((store) => store.user);

  if (store !== null) {
    return children;
  } else if (store === null) {
    return <Navigate to="login" />;
  }
};

export default PrivateRoute;
