import "./Emails.css";
import { useEffect, useState } from "react";
import EmailsPop from "../../Components/EmailsPop/EmailsPop";
import { useTranslation } from "react-i18next";
import axios, { all } from "axios";
import { Link, useSearchParams } from "react-router-dom";
import ResPop from "../../Components/ResPop/ResPop";
import ChangeStatusPage from "../../Components/ChangeStatusPage/ChangeStatusPage";
import ClockLoader from "react-spinners/ClockLoader";

function Emails() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [email, setEmail] = useState([]);
  const [received_email, setReceived_email] = useState([]);
  const [model, setModel] = useState();
  const [resPop, setResPop] = useState();
  const [response, setRespone] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState([]);
  const [noticeStatuses, setNoticeStatuses] = useState([]);
  const [changeStatus, setChangeStatus] = useState();

  const [total, setTotal] = useState();
  const [count, setCount] = useState();
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const [searchTerm, setSearchTerm] = useState();
  const [loading, setLoading] = useState(true);

  const [t, i18n] = useTranslation("global");

  const toggleModal = () => {
    setModel(!model);
  };

  const toggleResPop = (e) => {
    setResPop(e);
    // console.log(e);
  };

  const togglePop = () => {
    setResPop("t");
  };

  const handleEmails = (perPage, currentPage, search, status) => {
    var url = `email?filter=1`;
    if (perPage) {
      url += `&pagination=${perPage}`;
    }
    if (currentPage) {
      url += `&page=${currentPage}`;
    }
    if (status && status !== "all") {
      url += `&status_name=${status}`;
    }
    if (search) {
      url += `&search=${search}`;
    }

    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);

        setEmail(res.data.data);

        setTotal(res.data.pagination.total);
        setCount(res.data.pagination.count);
        setPerPage(res.data.pagination.per_page);
        setCurrentPage(res.data.pagination.current_page);
        setTotalPages(res.data.pagination.total_pages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStatuses = async () => {
    await axios.get("statuses").then((res) => {
      setStatuses(res.data.data);
    });
  };

  const handleDelete = async (ema) => {
    try {
      setEmail(email.filter((p) => p.id !== ema.id));
      await axios.delete(`email/delete/${ema.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      searchParams.get("page") ||
      searchParams.get("perPage") ||
      searchParams.get("status")
    ) {
      handleEmails(
        searchParams.get("perPage"),
        searchParams.get("page"),
        searchTerm,
        status
      );
      setFirstPage(
        Number(searchParams.get("page")) -
          ((Number(searchParams.get("page")) - 1) % 5)
      );
      setLastPage(
        Number(searchParams.get("page")) +
          (5 - ((Number(searchParams.get("page")) - 1) % 5) - 1)
      );
    } else {
      // handleEmails(perPage, currentPage, searchTerm, status);
    }
    handleStatuses();
  }, []);

  const [firstPage, setFirstPage] = useState(1);
  const [lastPage, setLastPage] = useState(5);

  const pageCount = Math.ceil(total / perPage);

  const pages = Array.from({ length: pageCount }, (v, k) => k + 1);

  const nextPage = () => {
    const newPage = lastPage + 1;
    setCurrentPage(newPage);
    setLoading(true);
    handleEmails(perPage, newPage, undefined, status);
    setFirstPage((prev) => prev + 5);
    setLastPage((prev) => prev + 5);
  };

  const prevPage = () => {
    const newPage = firstPage - 1;
    setCurrentPage(newPage);
    setLoading(true);
    handleEmails(perPage, newPage, undefined, status);
    setFirstPage((prev) => prev - 5);
    setLastPage((prev) => prev - 5);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    setLoading(true);
    handleEmails(e.target.value, 1, undefined, status);
    setFirstPage(1); // Reset the first page
    setLastPage(5); // Reset the last page to show the first set of pages
  };

  const page = Number(searchParams.get("page")) || 1;
  const pPage = Number(searchParams.get("perPage")) || 10;

  useEffect(() => {
    setSearchParams({ page: currentPage, perPage: perPage, status: status });
    console.log("its me");
  }, [perPage, currentPage, status, setSearchParams]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
      handleEmails(perPage, 1, searchTerm, status);
    }, 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  const handleChangeStatus = () => {
    setChangeStatus(!changeStatus);
  };

  return (
    <>
      <button className="btn btn-primary mt-5 col-2" onClick={toggleModal}>
        {t("control.add")}
      </button>

      {model && (
        <EmailsPop toggleModal={toggleModal} handleEmails={handleEmails} />
      )}

      <form className="d-flex m-3" role="search">
        <input
          className="form-control me-2"
          type="search"
          placeholder={t("search.search")}
          aria-label="Search"
          onChange={(e) => {
            // console.log(e.target.value);
            setSearchTerm(e.target.value);
          }}
        />
      </form>

      <div className="overflow-x-auto mb-4">
        <table className="tab-style table table-striped table-hover mt-5 fix-fonts">
          <thead>
            <tr>
              <td>Id</td>
              <td>{t("emails.title")}</td>
              <td>{t("emails.email")}</td>
              <td>{t("emails.files")}</td>
              <td>{t("emails.number")}</td>
              <td>{t("emails.received at")}</td>
              <td>Ip</td>
              <td>{t("emails.response")}</td>
              <td>{t("emails.responseFile")}</td>
              <td>
                <select
                  className="form-select status-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    handleEmails(
                      perPage,
                      currentPage,
                      searchTerm,
                      e.target.value
                    );
                    console.log(e.target.value);
                  }}
                >
                  <option value="all">all</option>
                  {statuses?.map((stat) => (
                    <>
                      <option value={stat.status_name}>
                        {stat.status_name}
                      </option>
                    </>
                  ))}
                </select>
                <span className="spa">{t("statuses.status")}</span>
              </td>
              <td>{t("control.actions")}</td>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <>
                <div className="pop-blur"></div>
                <tr>
                  <td colSpan="11">
                    <div className="din">
                      <ClockLoader
                        color={"#4fe000"}
                        loading={loading}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  </td>
                </tr>
              </>
            ) : (
              email?.map((ema) => (
                <tr key={ema.id}>
                  <td>{ema.id}</td>
                  <td>{ema.subject}</td>
                  <td
                    dangerouslySetInnerHTML={{ __html: ema.email.slice(0, 10) }}
                  ></td>

                  <td>
                    {ema.files.map((fi) => (
                      <>
                        <a
                          href={`https://btk.highspeed.com.tr/server${fi.file_path}`}
                        >
                          {fi.file_name}
                          <p></p>
                        </a>
                      </>
                    ))}
                  </td>
                  <td>{ema.number}</td>
                  <td>{ema.received_at}</td>

                  <td>{ema.ip}</td>

                  <td>
                    {ema.responses.length !== 0 ? (
                      <>
                        <div className="events">
                          <Link
                            to={`/emailsResponse/${ema.responses[0].id}`}
                            state={{
                              perPage,
                              currentPage,
                            }}
                            className="btn btn-success centers-action-btn how fix-font"
                          >
                            {t("control.edit")}
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="events">
                          <button
                            className="btn btn-primary centers-action-btn how fix-font"
                            onClick={() => toggleResPop(ema.id)}
                          >
                            {t("control.add")}
                          </button>
                        </div>

                        <div>
                          {resPop === ema.id && (
                            <ResPop
                              Id={ema.id}
                              toggleResPop={toggleResPop}
                              handleEmails={handleEmails}
                              togglePop={togglePop}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </td>
                  <td>
                    {ema.responses[0]?.files.map((fileee) => (
                      <>
                        <a
                          href={`https://btk.highspeed.com.tr/server${fileee.file_path}`}
                        >
                          {fileee.file_name}
                          <p></p>
                        </a>
                      </>
                    ))}
                  </td>
                  <td>
                    <>
                      <span className="">
                        {ema.last_status ? (
                          <>
                            <div className="fd">
                              <div
                                className="status-span"
                                style={{
                                  backgroundColor: ema.last_status.status_color,
                                }}
                              >
                                {ema.last_status.status_name}
                              </div>
                            </div>
                            <div className="events">
                              <Link
                                to={`/emailsStatus/${ema.id}`}
                                state={{
                                  last: ema.last_status.status_name,
                                  note: ema.last_status.notes,
                                }}
                                className="btn btn-primary centers-action-btn mt-1 how fix-font"
                                onClick={handleChangeStatus}
                              >
                                {t("control.edit")}
                              </Link>
                            </div>
                          </>
                        ) : (
                          <div className="events">
                            <div>no status</div>
                            <Link
                              to={`/emailsStatus/${ema.id}`}
                              className="btn btn-primary centers-action-btn mt-1 how fix-font"
                              onClick={handleChangeStatus}
                            >
                              {t("control.add")}
                            </Link>
                          </div>
                        )}
                      </span>

                      {changeStatus && (
                        <ChangeStatusPage
                          handleChangeStatus={handleChangeStatus}
                          handleEmails={handleEmails}
                          currentPage={currentPage}
                          perPage={perPage}
                        />
                      )}
                    </>
                  </td>
                  <td>
                    <Link
                      to={`/emails/${ema.id}`}
                      state={{
                        perPage,
                        currentPage,
                      }}
                      className="btn btn-success centers-action-btn fix-font"
                    >
                      {t("control.edit")}
                    </Link>

                    <button
                      type="button"
                      className="btn btn-danger centers-action-btn fix-font"
                      onClick={() => handleDelete(ema)}
                    >
                      {t("control.delete")}
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div className="page-style">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {firstPage > 1 && (
              <li className="page-item">
                <a
                  className="page-link hide-page-under-pop"
                  style={{ cursor: "pointer" }}
                  onClick={() => prevPage()}
                >
                  Previous
                </a>
              </li>
            )}
            {firstPage > 1 && (
              <li className="page-item">
                <span className="page-link hide-page-under-pop">...</span>
              </li>
            )}
            {pages.slice(firstPage - 1, lastPage).map((page) => (
              // ... (باقي الكود الحالي لعرض أرقام الصفحات)
              <li
                key={page}
                className={
                  page === currentPage ? "page-item active" : "page-item"
                }
              >
                <a
                  style={{ cursor: "pointer" }}
                  className="page-link hide-page-under-pop"
                  onClick={() => {
                    setCurrentPage(page);
                    setLoading(true);
                    handleEmails(perPage, page, undefined, status);
                  }}
                >
                  {page}
                </a>
              </li>
            ))}
            {lastPage < pageCount && (
              <li className="page-item">
                <span className="page-link hide-page-under-pop">...</span>
              </li>
            )}
            {lastPage < pageCount && (
              <li className="page-item">
                <a
                  className="page-link hide-page-under-pop"
                  style={{ cursor: "pointer" }}
                  onClick={() => nextPage()}
                >
                  Next
                </a>
              </li>
            )}
          </ul>
        </nav>

        <select
          className="form-select form-select-sm perpage"
          aria-label="Small select example"
          onChange={(e) => handlePerPageChange(e)}
        >
          <option value="2" selected={2 == perPage}>
            2
          </option>
          <option value="3" selected={3 == perPage}>
            3
          </option>
          <option value="5" selected={5 == perPage}>
            5
          </option>
          <option value="10" selected={10 == perPage}>
            10
          </option>
        </select>
      </div>
    </>
  );
}

export default Emails;
